import {
  shortenInstaImgAlts,
  fixAccessibilityIssuesWithInterval,
} from "../accessibility";

export const mainPageConfig = [
  {
    class: ".instagram-feed--obs",
    action: "load3rdPartyScript",
    src: "https://starling.crowdriff.com/js/crowdriff.js",
    attributes: {
      id: "cr-init__99de65678ba8e81a",
    },
    onload: function () {
      fixAccessibilityIssuesWithInterval(shortenInstaImgAlts);
      setTimeout(() => {
        document
          .querySelector(".instagram-feed--obs")
          .classList.remove("loading");
      }, 1500);
    },
    parentClass: ".instagram-feed--obs .cr_wrapper",
  },
  {
    class: ".bg-lazy--obs",
    action: "lazyLoadBgImage",
    classToAdd: "lazy-loaded",
  },
];
