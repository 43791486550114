import "owl.carousel";
import "select2";
import {
  fixAccessibilityIssues,
  fixAccessibilityIssuesWithInterval,
} from "./accessibility";
import { loadVentrataScript } from "./loadVentrataScript";
import { loadRefersionTracking } from "./loadRefersionTracking";
import {
  observer,
  loadImmediately,
  mainPageConfig,
} from "./intersection_observer_scripts";

window.$ = jQuery;
(function ($, Drupal, window) {
  "use strict";
  Drupal.behaviors.esb_theme = {
    attach: async function (context, settings) {
      let winsize = getWinSize();

      // ajax fixes
      if (context !== window.document) {
        applyChangesDuringEveryAttach(context, settings);
        return false;
      }

      //////////////////////////////////////////////////////////////
      // scripts to run on scroll
      //////////////////////////////////////////////////////////////

      function onScroll() {
        var scrollPos = $(document).scrollTop();
        var winHeight = $(window).height();

        // handle sticky nav

        var $banner = $(".region-header-banner");

        if (scrollPos > 0) {
          if ($("body").hasClass("ticket")) {
            var bh = $banner.height();

            if (scrollPos > bh) {
              $("#global-header")
                .removeClass("is-sticky")
                .addClass("scroll-no-sticky");
              $banner.find(".banner--content").addClass("is-sticky");
            } else {
              $("#global-header")
                .addClass("is-sticky")
                .removeClass("scroll-no-sticky");
              $banner.find(".banner--content").removeClass("is-sticky");
            }
          } else {
            $("#global-header").addClass("is-sticky");
          }
        } else {
          $("#global-header")
            .removeClass("is-sticky")
            .removeClass("scroll-no-sticky");
          $banner.find(".banner--content").removeClass("is-sticky");
        }

        // custom popup block

        $(".simple-popup-block").fadeOut(500);

        // handle paragraph rendering

        if ($(".paragraph--type--single-feature-container").length) {
          $.each(
            $(".paragraph--type--single-feature-container"),
            function (k, v) {
              var offset = $(this).offset().top;

              if (scrollPos >= offset - winHeight * 0.85) {
                $(this).addClass("is-rendered");
              }
            }
          );
        }

        if ($(".paragraph--type--two-features-container").length) {
          $.each($(".paragraph--type--two-features-container"), function () {
            var offset = $(this).offset().top;

            if (scrollPos >= offset - winHeight * 0.85) {
              $(this).addClass("is-rendered");
            }
          });
        }

        if ($(".paragraph--type--two-featured-tickets").length) {
          $.each($(".paragraph--type--two-featured-tickets"), function () {
            var offset = $(this).offset().top;

            if (scrollPos >= offset - winHeight * 0.85) {
              $(this).addClass("is-rendered");
            }
          });
        }

        // END: onScroll()
        return true;
      }
      // register event listener
      window.addEventListener("scroll", onScroll, { passive: true });

      //////////////////////////////////////////////////////////////
      // scripts to run on resize
      //////////////////////////////////////////////////////////////

      function onResize() {
        winsize = getWinSize();

        // reset classes

        if (winsize.desktop) {
          $(".global-header__right-block").removeClass("is-active");
          $(".menu-item").removeClass("is-expanded");
          $("body").removeClass("menu-expanded");
        }

        // adjust elements height to be equal to the bigger one

        var selectors = [".paragraph--type--small-logo .field_logos"];

        if ($(window).width() <= 1024) {
          for (var i = 0; i < selectors.length; i++) {
            var $elements = $(selectors[i]);

            $elements.each(function () {
              $(this).css("height", "auto");
            });
          }
        } else {
          for (var i = 0; i < selectors.length; i++) {
            var $elements = $(selectors[i]);
            var height = 0;

            $elements.each(function () {
              var $el = $(this);
              $el.css("height", "auto");

              if ($el.height() > height) {
                height = $el.height();
              }
            });

            $elements.each(function () {
              $(this).height(height);
            });
          }
        }

        // END: onResize()
        return true;
      }
      // register event listener
      $(window).on("resize", onResize);

      //////////////////////////////////////////////////////////////
      // scripts to run on load
      //////////////////////////////////////////////////////////////

      function onLoad() {
        onScroll();
        onResize();
        surveyCTA();
        refersionTrackingCrossDomain();

        // mobile detection

        var isMobile = false;
        if (
          /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(
            navigator.userAgent
          ) ||
          /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
            navigator.userAgent.substr(0, 4)
          )
        ) {
          isMobile = true;
        }

        // mobile actions

        if (isMobile) {
          $(".hide-from-mobile").hide();
          $(".three-cloumn").show();

          if (window.location.href.endsWith("buy-tickets")) {
            location.replace(
              window.location.href.replace(
                "buy-tickets",
                "buy-tickets#main-deck"
              )
            );
          }

          $('a[href^="#"]').on("click", function (event) {
            if ($("div.field_pre_title").length) {
              $("html, body").animate(
                {
                  scrollTop: $("div.field_pre_title").offset().top,
                },
                1000
              );
            }
          });
        } else {
          $(".hide-from-mobile").show();
          $(".three-cloumn").hide();
        }

        // new window anchors

        $('a[target="_blank"]', context).each(function () {
          var title = $(this).attr("title");

          if (title === undefined) {
            title = "";
          }

          $(this).attr("title", title + " (Opens in new window)");
        });

        // select boxes

        $(".app-container select", context).each(function (i, el) {
          applySelect2(this, settings);
        });

        $(document).on("click", ".pseudo-dropdown", function () {
          $(this).toggleClass("is-expanded");
        });

        $(document).on("keydown", ".pseudo-dropdown", function (e) {
          if (e.which == 13) {
            e.preventDefault();

            $(this).trigger("click");
            $(this).find("button:not(.active)").trigger("click");
          }
        });

        // form colorpicker functions

        $(document).on("click", "input.form-color-output", function (e) {
          var p = $(this).parent();

          $(p.find('input[type="color"]')).trigger("click");
        });

        // slider - all elements

        if ($(".owl-carousel").length) {
          $(".owl-carousel").owlCarousel({
            center: true,
            items: 1,
            loop: true,
            margin: 24,
            autoWidth: true,
            responsive: {
              768: {
                items: 2,
              },
              1025: {
                items: 6,
                loop: false,
                autoWidth: false,
              },
            },
          });
        }

        // slider - tower lights block

        if ($("body").hasClass("tower_lights_page")) {
          var slideEl = $(".three-days-lights-wrapper .days-wrapper");

          slideEl.owlCarousel({
            center: true,
            items: 1,
            loop: false,
            margin: 10,
            autoWidth: true,
            nav: false,
            dots: false,
            responsive: {
              1025: {
                items: 3,
                loop: false,
                autoWidth: false,
                margin: 24,
              },
            },
            onResize: centerSlides,
          });

          slideEl.trigger("to.owl.carousel", 1);
        }

        // slider - quotes

        if ($(".paragraph--type--quote-slider").length) {
          $(
            ".paragraph--type--quote-slider .layout__region--content > div > div"
          ).addClass("quote-slider");
          $(".paragraph--type--quote-slider .quote-slider").owlCarousel({
            items: 1,
            singleItem: true,
            loop: true,
            nav: true,
            autoplay: false,
          });
        }

        // slider - single feature

        if ($(".paragraph--type--single-feature-slider").length) {
          $.when(
            $(".field_single_feature_slider").owlCarousel({
              items: 1,
              singleItem: true,
              loop: true,
              margin: 24,
              nav: true,
              autoplay: true,
              autoplayTimeout: 10000,
            })
          ).then(sliderUrlQuery());
        }

        // slider - timeline point

        if ($(".field_timeline_point").length) {
          $(".field_timeline_point").owlCarousel({
            center: false,
            items: 1,
            margin: 15,
            nav: true,
            dots: false,
            stagePadding: 20,
            singleItem: true,
            responsive: {
              768: {
                singleItem: false,
                items: 3,
                stagePadding: 50,
              },
            },
          });
        }

        // slider - media sf

        if (
          $(".paragraph--type--image-title-text .field_media_sf > div").length >
          0
        ) {
          $(".paragraph--type--image-title-text .field_media_sf").owlCarousel({
            center: false,
            items: 1,
            nav: true,
            dots: false,
            loop: true,
            singleItem: true,
          });
        }

        // slider - featured blog

        if ($("#block-esb-theme-views-block-blog-block-2").length) {
          $(
            "#block-esb-theme-views-block-blog-block-2 .view-content"
          ).owlCarousel({
            items: 1,
            loop: false,
            margin: 24,
            nav: true,
            autoplay: true,
            autoplayTimeout: 15000,
            autoHeight: true,
            responsive: {
              768: {
                stagePadding: 50,
              },
            },
          });
        }

        // embed youtube channel

        if ($("#youtubechannel-list ul").length) {
          $("#youtubechannel-list ul").addClass("youtube-channel-loaded");
          $("#youtubechannel-list ul").owlCarousel({
            items: 1,
            singleItem: true,
            autoHeight: true,
            center: true,
            loop: false,
            margin: 24,
            nav: true,
            dots: true,
            responsive: {
              768: {
                center: false,
                items: 3,
                dots: false,
              },
            },
          });
        }

        // image carousel

        if (
          $(".paragraph--type--image-carousel .field_carousel_image").length
        ) {
          $(
            ".paragraph--type--image-carousel .field_carousel_image"
          ).owlCarousel({
            items: 1,
            loop: false,
            margin: 0,
            nav: true,
            dots: false,
            lazyLoad: true,
            thumbs: true,
            thumbsPrerendered: true,
            onChanged: function (e) {
              $(".thumbnail-carousel img").removeClass("active-img");
              $(
                ".thumbnail-carousel img[data-slide=" + e.item.index + "]"
              ).addClass("active-img");
              $(".thumbnail-carousel").trigger("to.owl.carousel", e.item.index);
            },
          });

          $(".thumbnail-carousel").owlCarousel({
            items: 3,
            loop: false,
            margin: 15,
            autoWidth: true,
            nav: false,
            dots: false,
            responsive: {
              768: {
                center: false,
                items: 7,
              },
            },
          });

          $(".thumbnail-carousel img").on("click", function () {
            $(".paragraph--type--image-carousel .field_carousel_image").trigger(
              "to.owl.carousel",
              $(this).data("slide")
            );
          });
        }

        // paragraph--type--video-slider

        if ($(".paragraph--type--video-slider .field_video").length) {
          $(".paragraph--type--video-slider .field_video").owlCarousel({
            items: 1,
            loop: true,
            margin: 24,
            nav: true,
            dots: true,
            responsive: {
              768: {
                items: 3,
                dots: false,
              },
            },
          });
        }

        if (
          $(".paragraph--type--three-featured-tickets > div").length > 1 &&
          window.innerWidth < 1024
        ) {
          $(".paragraph--type--three-featured-tickets").owlCarousel({
            items: 1,
            loop: false,
            nav: true,
            dots: true,
          });
        }

        // celebrities page

        if ($(".celebrities-view").length) {
          var qsRegex, $grid;

          $grid = $(".celebrities-view .grid").isotope({
            itemSelector: ".celebrity-pic",
            layoutMode: "masonry",
            percentPosition: true,
            getSortData: {
              created: "[data-created]",
            },
            filter: function () {
              if ($(this).data("name")) {
                var match = $(this).data("name").match(qsRegex);

                return qsRegex ? match : true;
              }

              return true;
            },
            masonry: {
              columnWidth: ".grid-sizer",
              gutter: 28,
            },
          });

          $grid.imagesLoaded(function () {
            $grid.removeClass("preload");
          });

          $grid.on("layoutComplete", function (event, filteredItems) {
            if (!filteredItems.length) {
              $(".no-results").addClass("active");
            } else {
              $(".no-results").removeClass("active");
            }
          });

          var initShow = 12;
          var counter = initShow;
          var iso = $grid.data("isotope");

          // load more reslts function
          function loadMore(toShow) {
            $grid.find(".hidden").removeClass("hidden");
            var hiddenElems = iso.filteredItems
              .slice(toShow, iso.filteredItems.length)
              .map(function (item) {
                return item.element;
              });

            $(hiddenElems).addClass("hidden");
            $grid.isotope("layout");

            //when no more to load, hide show more button
            if (hiddenElems.length == 0) {
              $("#showMore").hide();
            } else {
              $("#showMore").show();
            }
          }

          // handle load more
          if ($(".celebrities-view #showMore").length) {
            loadMore(initShow);

            //when load more button clicked
            $("#showMore").click(function () {
              counter = counter + initShow;
              loadMore(counter);
            });

            $("#showMore").on("keydown", function (e) {
              if (e.which == 13) {
                e.preventDefault();
                $(this).trigger("click");
              }
            });
          }

          // use value of search field to filter
          if ($(".quicksearch").length) {
            var $quicksearch = $(".quicksearch").keyup(
              debounce(function () {
                qsRegex = new RegExp($quicksearch.val(), "gi");

                if ($quicksearch.val().trim() === "") {
                  // reset filter and view
                  $grid.isotope();
                  loadMore(initShow);
                } else {
                  // do filter and show all
                  $grid.isotope();
                }
              }, 200)
            );
          }

          // debounce so filtering doesn't happen every millisecond
          function debounce(fn, threshold) {
            var timeout;

            threshold = threshold || 100;

            return function debounced() {
              clearTimeout(timeout);

              var args = arguments;
              var _this = this;

              function delayed() {
                fn.apply(_this, args);
              }

              timeout = setTimeout(delayed, threshold);
            };
          }

          $(".sort-by .pseudo-dropdown button").on("click", function () {
            var sortValue = $(this).attr("data-sort-value");
            var direction =
              $(this).attr("data-sort-direction") == "asc" ? false : true;

            // pass it to isotope
            $grid.isotope({ sortBy: sortValue, sortAscending: direction });

            $(this).siblings().removeClass("active");
            $(this).addClass("active");
          });

          // $(".celebrities-view .celebrity-pic > img").click(function() {
          //   $("body").addClass("locked");
          //   $(this).parent().addClass("selected");
          //   $(".main-footer-container").css("z-index", "1");
          //   $(".btn-div").hide();
          // });

          if (window.location.hash) {
            $(window.location.hash + " > img").click();
          }

          $(".celebrities-view .celebrity-pic__mask").click(function () {
            $("body").removeClass("locked");
            $(".celebrity-pic.selected").removeClass("selected");
            $(".main-footer-container").css("z-index", "2");
            $(".btn-div").show();
          });

          $(".celebrities-view .celebrity-pic__close").click(function () {
            $("body").removeClass("locked");
            $(".celebrity-pic.selected").removeClass("selected");
            $(".main-footer-container").css("z-index", "2");
            $(".btn-div").show();
          });

          $(".celebrities-view__btn--prev").click(function () {
            var len = $(".celebrity-pic").length;
            var sort = $(".pseudo-dropdown > button.active").attr(
              "data-sort-direction"
            );
            var curr = $(".celebrity-pic.selected");

            // check for sort direction and move in corresponding direction
            if (sort == "desc") {
              if (
                $(".celebrities-view .grid .celebrity-pic.selected").index() ==
                1
              ) {
                $(".celebrity-pic").last().addClass("selected");
              } else {
                curr.prev().addClass("selected");
              }
            } else if (sort == "asc") {
              if (
                $(".celebrities-view .grid .celebrity-pic.selected").index() >=
                len
              ) {
                $(".celebrity-pic").first().addClass("selected");
              } else {
                curr.next().addClass("selected");
              }
            }

            curr.removeClass("selected");
            $(".main-footer-container").css("z-index", "1");
            $(".btn-div").hide();
          });

          $(".celebrities-view__btn--next").click(function () {
            var len = $(".celebrity-pic").length;
            var sort = $(".pseudo-dropdown > button.active").attr(
              "data-sort-direction"
            );
            var curr = $(".celebrity-pic.selected");

            // check for sort direction and move in corresponding direction
            if (sort == "desc") {
              if (
                $(".celebrities-view .grid .celebrity-pic.selected").index() >=
                len
              ) {
                $(".celebrity-pic").first().addClass("selected");
              } else {
                curr.next().addClass("selected");
              }
            } else if (sort == "asc") {
              if (
                $(".celebrities-view .grid .celebrity-pic.selected").index() ==
                1
              ) {
                $(".celebrity-pic").last().addClass("selected");
              } else {
                curr.prev().addClass("selected");
              }
            }

            curr.removeClass("selected");
            $(".main-footer-container").css("z-index", "1");
            $(".btn-div").hide();
          });
        }

        // masonry

        if ($(".masonry-view").length) {
          $(".masonry-view .is-isotope", context).each(function () {
            var qsRegex;

            // isotope init and options
            var $grid = $(this).imagesLoaded(function () {
              $grid.isotope({
                itemSelector: ".isotope-item",
                layoutMode: "masonry",
                percentPosition: true,
                getSortData: {
                  created: "[data-created]",
                },
                filter: function () {
                  if ($(this).data("name")) {
                    var match = $(this).data("name").match(qsRegex);

                    // show all filtered
                    if (match !== null && match.length > 0) {
                      $(this).removeClass("hidden");
                    }

                    // always hide load more
                    $("#showMore").hide();

                    return qsRegex ? match : true;
                  }
                  return true;
                },
                masonry: {
                  // set to the element
                  columnWidth: ".grid-sizer",
                  gutter: 28,
                },
              });
            });

            $grid.on("layoutComplete", function (event, filteredItems) {
              if (!filteredItems.length) {
                $(".no-results").addClass("active");
              } else {
                $(".no-results").removeClass("active");
              }
            });

            var initShow = 12;
            var counter = initShow;
            var iso = $grid.data("isotope");

            // handle load more
            if ($(".masonry-view #showMore").length) {
              loadMore(initShow);

              //when load more button clicked
              $("#showMore").click(function () {
                counter = counter + initShow;
                loadMore(counter);
              });

              $("#showMore").on("keydown", function (e) {
                if (e.which == 13) {
                  e.preventDefault();
                  $(this).trigger("click");
                }
              });
            }

            // use value of search field to filter
            if ($(".quicksearch").length) {
              var $quicksearch = $(".quicksearch").keyup(
                debounce(function () {
                  qsRegex = new RegExp($quicksearch.val(), "gi");

                  if ($quicksearch.val().trim() === "") {
                    // reset filter and view
                    $grid.isotope();
                    loadMore(initShow);
                  } else {
                    // do filter and show all
                    $grid.isotope();
                  }
                }, 200)
              );
            }

            // sort functionality not confirmed
            $(".sort-by").on("click", "button", function () {
              // get the element name to sort
              var sortValue = $(this).attr("data-sort-value");

              // get the sorting direction: asc||desc
              var direction = $(this).attr("data-sort-direction");

              // convert it to a boolean
              var isAscending = direction == "asc";

              // pass it to isotope
              $grid.isotope({ sortBy: sortValue, sortAscending: isAscending });

              $(this).siblings().removeClass("active");
              $(this).addClass("active");
            });
          });

          $.when(
            setupMagnificPopup(
              ".isotope-item [class^=field_media_image] > a, .isotope-item .media > a"
            )
          ).then(openCelebPic());

          $(".isotope-item article").on("click", function (e) {
            e.preventDefault();

            if ($(this).find(".video-link").length) {
              $(this).find(".video-link").trigger("click");
            } else {
              $(this).find("[class^=field_media_image] a").trigger("click");
            }

            return false;
          });
        }

        // open in popup

        setupMagnificPopup(
          ".media--view-mode-on-3-cols .video-link, \
          .media--view-mode-on-2-cols .video-link, \
          .paragraph--type--single-video .video-link, \
          .media--view-mode-default .video-link"
        );

        var targetNode = $(
          "#block-esb-theme-content form > .form-wrapper > .js-form-wrapper"
        );
        var MutationObserver =
          window.MutationObserver ||
          window.WebKitMutationObserver ||
          window.MozMutationObserver;
        var obsConfig = {
          attributes: true,
          childList: true,
          characterData: true,
          subtree: true,
        };

        targetNode.each(function () {
          var observer = new MutationObserver(function (mutations) {
            mutations.forEach(function (mutation) {
              if (mutation.type === "childList") {
                var added = mutation.addedNodes;

                if (added.length) {
                  var newRow = false;

                  $.each(added, function (k, v) {
                    if ($(v).find("table").length) {
                      newRow = true;
                    }
                  });

                  if (newRow) {
                    var selects = $("#block-esb-theme-content form select");

                    $(selects).each(function () {
                      $(this).select2({
                        minimumResultsForSearch: Infinity,
                      });
                    });
                  }
                }
              }
            });
          });

          observer.observe(this, obsConfig);
        });

        // timeline chapters
        if ($(".node--type-timeline").length) {
          var chapters = [];
          var curr_chapter = -1;
          var ux;

          ux = $("<ul class='chapter-ux'/>").appendTo(
            $(".node--type-timeline")
          );

          parseChapters(chapters, ux);
          window.addEventListener(
            "scroll",
            startTimeline(chapters, ux, curr_chapter),
            {
              passive: true,
            }
          );
          startTimeline(chapters, ux, curr_chapter);
        }

        // logo paragraphes

        if ($(".paragraph--type--logos-list").length) {
          $(
            ":not(.paragraph--type--logos-list.list-size-half) + .paragraph--type--logos-list.list-size-half, * > .paragraph--type--logos-list.list-size-half:first-of-type"
          ).each(function () {
            $(this)
              .nextUntil(":not(.paragraph--type--logos-list.list-size-half)")
              .addBack()
              .wrapAll('<div class="logo-list-div" />');
          });
        }

        // smooth scroll hashes

        var hash = window.location.hash.substring(1);

        if (hash) {
          var element = $('a[name="' + hash + '"]');
          if ($(element).offset()) {
            var top = $(element).offset().top;
            var navH = $("header").height();

            $("html, body").animate({
              scrollTop: top - navH - 50,
            });
          }
        }

        // arrow button to scroll down

        $(".scroll-down").click(function (e) {
          e.preventDefault();

          var y = $("#block-esb-theme-content").offset().top;
          var d = $("header").outerHeight() + 40;

          $("html, body").animate({
            scrollTop: y - d,
          });
        });

        // search form click functionality

        let searchForm = $(".form-search").parents("form");

        $("html").click(function (e) {
          if ($(e.target).hasClass("form-search")) {
            $(searchForm).addClass("focus");
            $(".form-search").attr(
              "placeholder",
              $(searchForm).data("ph-focus")
            );
          } else {
            $(searchForm).removeClass("focus");
            $(".form-search").attr(
              "placeholder",
              $(searchForm).data("ph-blur")
            );
          }
        });

        // group sales form to salesforce

        $(".reason-for-visit").hide();
        $(".reason-for-visit-other").hide();
        $(".public-school-funding").hide();
        $(".group-type-other").hide();

        $(".group-type-selector").change(function () {
          if (
            $(this).val() == "School Group" ||
            $(this).val() == "Tour Operator School Groups"
          ) {
            $(".reason-for-visit").show();
            $(".public-school-funding").show();
          } else {
            $(".reason-for-visit").hide();
            $(".public-school-funding").hide();
          }
          if ($(this).val() == "Other (Please specify)") {
            $(".group-type-other").show();
          } else {
            $(".group-type-other").hide();
          }
        });

        $(".reason-for-visit-selector").change(function () {
          if ($(this).val() == "Other (Please specify)") {
            $(".reason-for-visit-other").show();
          } else {
            $(".reason-for-visit-other").hide();
          }
        });

        // contact us form to salesforce

        $(".order-number-question").hide();
        $(".order-number").hide();
        $(".double-charge").hide();

        $(".category-selector").change(function () {
          if ($(this).val() == "Refunds") {
            $(".order-number-question").show();
          } else {
            $(".order-number-question").hide();
            $(".order-number").hide();
            $(".order-number-checkbox").attr("checked", false);
          }
          if ($(this).val() == "Double Charges") {
            $(".double-charge").show();
          } else {
            $(".double-charge").hide();
          }
        });

        $(".order-number-selector").change(function () {
          if ($(this).val() == "Yes") {
            $(".order-number").show();
            $(".order-number-checkbox").attr("checked", true);
          } else {
            $(".order-number").hide();
            $(".order-number-checkbox").attr("checked", false);
          }
        });

        // add success message

        if (
          $('input[name="retURL"]').length > 0 &&
          window.location.hash == "#success"
        ) {
          var $message, $messages;

          $message = $('<div class="messages messages--status">').text(
            "Your message has been sent."
          );
          $messages = $("<div data-drupal-messages>").append($message);
          $(".region-top-content").append($messages);
        }

        // loading on form submissions

        $(".search-block-form form, .views-exposed-form").submit(function () {
          $("body").addClass("loading");
          $('input[name="csrf_token"]').prop("disabled", true);
        });

        // responsive nav

        $(".responsive-nav-menu .hamburger").on("click", function () {
          $(this)
            .parents(".global-header__right-block")
            .toggleClass("is-active");
          $("#global-header").toggleClass("is-active");
          $("body").toggleClass("menu-expanded");
          $(".menu-item").removeClass("is-expanded");
        });

        $(".menu-item > .mobile-arrow").on("click", function () {
          $(this).closest(".menu-item").toggleClass("is-expanded");
        });

        // home page video button

        $(".btn-play-pause")
          .unbind()
          .click(function (e) {
            e.preventDefault();

            var vid = $(this).next("video").get(0);
            $(this).toggleClass("is-paused");

            if (vid.paused) {
              vid.play();
              $(this).attr("title", "Pause Background Video");
            } else {
              vid.pause();
              $(this).attr("title", "Play Background Video");
            }
          });

        // article hover class

        $(".article-container").hover(
          function () {
            $(this).parent().addClass("hover");
          },
          function () {
            $(this).parent().removeClass("hover");
          }
        );

        $(".node--type-ticket .content").hover(
          function () {
            $(this).parent().addClass("hover");
          },
          function () {
            $(this).parent().removeClass("hover");
          }
        );

        // handle banners with videos

        if (
          $(".banner--image").length &&
          $(".banner--image").hasClass("is-video")
        ) {
          setTimeout(function () {
            $(".banner--image .btn-play-pause").removeClass("is-hidden");
          }, 5000);
        }

        // faq category accordion

        $(".paragraph--type--faq-category .field_simple_title").on(
          "click",
          function () {
            var category = $(this).closest(".paragraph--type--faq-category");

            category.toggleClass("is-open");
          }
        );

        // article detail pdf click

        $(
          ".node--type-article.node--view-mode-full .field_pdf .file--application-pdf"
        ).on("click", function () {
          var url = $(this).find("a").attr("href");

          window.open(url, "_blank");
        });

        // buy tickets functionality

        var buyTixPopUp = $("#block-buyticketscovidpopup");
        buyTixPopUp.append($('<span id="buy-tix-popup-close"></span>'));
        buyTixPopUp.on("click", "#buy-tix-popup-close", function () {
          buyTixPopUp.removeClass("show");
        });
        buyTixPopUp.addClass("show");

        // custom popup block

        $(".simple-popup-block").each(function (i, popup) {
          var cookie_id = "ACQ_close-" + $(popup).attr("id");

          $(popup)
            .find(".modal-close-button")
            .click(function () {
              $(popup).fadeOut(500);
              setCookie(cookie_id, "true", 1);
            });

          if (getCookie(cookie_id) != "true") {
            $(popup).show();
            setCookie(cookie_id, "true", 1);
          }

          setTimeout(function () {
            $(popup).fadeOut(500);
            setCookie(cookie_id, "true", 1);
          }, 8000);
        });

        // video settings

        let video = document.querySelector(
          ".paragraph--type--text-video video"
        );

        if (window.innerWidth < 720 && video != undefined) {
          video.controls = true;
        }

        // cookie logic for earthcam
        var cookieCheck = Drupal.eu_cookie_compliance.hasAgreed();
        // console.log({ cookieCheck });
        //var cookieCheck = false;

        if (!cookieCheck) {
          var earthCamScript = $(".earthcam-embed-container iframe").attr(
            "src"
          );

          $(".earthcam-embed-container iframe").attr("src", "");
          $(".earthcam-embed-container").append(
            '<h3 style="font-size:26px;max-width: 920px; margin: auto;" id="cookie-error">You must accept site cookies to view the EarthCam</h3>'
          );

          const onAcceptCookies = () => {
            Drupal.eu_cookie_compliance.setStatus("2");
          };

          const onDeclineCookies = () => {
            Drupal.eu_cookie_compliance.setStatus("0");
          };

          if (!$("#sliding-popup").length) {
            $("body.path-about-live-cam").append(`
              <div id="sliding-popup" class="sliding-popup-bottom" style="height: auto; width: 100%; bottom: 0px;">
                <div class="eu-cookie-compliance-banner eu-cookie-compliance-banner-info eu-cookie-compliance-banner--opt-in">
                  <div class="popup-content info eu-cookie-compliance-content">
                    <div id="popup-text" class="eu-cookie-compliance-message">
                      <h2>We use cookies on this site to enhance your user experience</h2>
                      <p>By clicking the Accept button, you agree to us doing so.</p>
                    </div>
                    <div id="popup-buttons" class="eu-cookie-compliance-buttons">
                      <button type="button" class="agree-button eu-cookie-compliance-default-button">Accept</button>
                      <button type="button" class="decline-button eu-cookie-compliance-secondary-button">No, thanks</button>
                    </div>
                  </div>
                </div>
              </div>
            `);
          }

          $("#sliding-popup .agree-button").on("click", function () {
            $("#sliding-popup").hide();
            $(".earthcam-embed-container #cookie-error").hide();
            $(".earthcam-embed-container").append(
              '<h3 style="font-size:26px;max-width: 920px; margin: auto;" id="cookie-accepted">Loading EarthCam...</h3>'
            );
            $(".earthcam-embed-container iframe").attr("src", earthCamScript);
            onAcceptCookies();
          });

          $("#sliding-popup .decline-button").on("click", function () {
            $("#sliding-popup").hide();
            onDeclineCookies();
          });
        }

        // video hero mobile

        if ($("#block-videohero video").length) {
          $("#block-videohero video").attr("playsinline", "playsinline");
          document.querySelector("#block-videohero video").play();
        }

        // contact us category url query

        if (
          window.location.pathname == "/contact-us" &&
          window.location.search.includes("?")
        ) {
          var contact_category = window.location.search.split("?category=")[1];

          if (contact_category == "accessibility") {
            $("#select2-edit-category--2-container").attr(
              "title",
              "Reasonable Accommodations for People with Disabilities"
            );
            $("#select2-edit-category--2-container").html(
              "Reasonable Accommodations for People with Disabilities"
            );
          }
        }

        // salesforce ip address field

        if ($("#00N4P00000GfNr").length) {
          $.get("https://www.cloudflare.com/cdn-cgi/trace", function (data) {
            data = data
              .trim()
              .split("\n")
              .reduce(function (obj, pair) {
                pair = pair.split("=");
                return (obj[pair[0]] = pair[1]), obj;
              }, {});

            $("#00N4P00000GfNr").val(data["ip"]);
          });
        }

        // influencer app - pull options from crunchbase
        $("#edit-brands-previously-worked-with").select2("destroy"); // destroy initial select2 setup
        $("#edit-brands-previously-worked-with").select2({
          minimumResultsForSearch: -1,
          placeholder: "Search for a brand",
          minimumInputLength: 1,
          tags: true,
          cache: false,
          multiple: true,
          ajax: {
            type: "POST",
            url: "/esb-crunchbase",
            contentType: "application/json; charset=utf-8",
            dataType: "json",
            delay: 250,
            data: function (params) {
              return JSON.stringify({ data: params.term });
            },
            processResults: function (data) {
              return {
                results: data,
              };
            },
          },
        });

        // gtranslate dropdown

        if ($(".gt_switcher").length > 0) {
          // hover action
          $(".gt_switcher").hover(
            function (e) {
              $(this).addClass("open");
            },
            function (e) {
              $(this).removeClass("open");
            }
          );
          // click action
          $(".gt_selected a").click(function (e) {
            $(".gt_switcher").toggleClass("open");
          });
          // hotfix for gtranslate mobile
          var $gtranslate = $("#block-esb-theme-gtranslate").clone();
          $gtranslate.addClass("langdropdown-wrapper");
          $(".right-header-wrapper").append($gtranslate);
          $gtranslate.find("img").each(function () {
            var lazySrc = $(this).attr("data-gt-lazy-src");
            $(this).attr("src", lazySrc);
          });
          $gtranslate.hover(
            function (e) {
              $(".gt_switcher").addClass("open");
            },
            function (e) {
              $(".gt_switcher").removeClass("open");
            }
          );
          $gtranslate.find("a").click(function (e) {
            $(".gt_switcher").toggleClass("open");
          });
          // HOLD: replace name with code
          // var lang_code = $('.gt_current').data('gt-lang');
          // var flag_img = $('.gt_selected img').prop('outerHTML');
          // $('.gt_selected a').html(flag_img + lang_code);
        }

        // search blog input
        $("input[name=search_blog]").attr(
          "form",
          "views-exposed-form-blog-block-1"
        );

        // blog remove filters
        $(document).on(
          "DOMNodeInserted",
          "#block-esb-theme-views-block-blog-block-1",
          function (e) {
            $("input[name=search_blog]").attr(
              "form",
              "views-exposed-form-blog-block-1"
            );
            $(this)[0].scrollIntoView();
            const filters = $(e.target).find(
              ".exposed-filter-values p.filter-tag"
            );
            $(filters).click(function (e) {
              const id = e.currentTarget.id;
              $(
                "#block-esb-theme-views-block-blog-block-1 form input[type=checkbox]"
              ).each(function (i, box) {
                if (id == box.value) {
                  $(box).prop("checked", false);
                  $(
                    "#block-esb-theme-views-block-blog-block-1 input[type=submit]"
                  ).click();
                }
              });
            });

            // reappend after filtering
            if (
              window.innerWidth < 1080 &&
              $(
                "#views-exposed-form-blog-block-1 .fieldgroup + .exposed-filter-values"
              ).length == 0
            ) {
              $(".exposed-filter-values").insertAfter(
                $("#views-exposed-form-blog-block-1 .fieldgroup")
              );
            }
          }
        );

        // setting input based on URL params
        if (window.location.search.includes("?field_category_target_id[]")) {
          const params = new URLSearchParams(window.location.search);
          const blogCatId = params.get("field_category_target_id[]");
          $(
            "#block-esb-theme-views-block-blog-block-1 form input[value=" +
              blogCatId +
              "]"
          ).prop("checked", true);
        }

        // replace smaller images with larger for youtube channel embed
        $("#youtubechannel-list ul img").each(function (i, img) {
          img.src = img.src.replace("default", "sddefault");
        });

        $("#youtubechannel-list ul li a").on("click", function () {
          $("body").addClass("lightbox-showing");
          $("#youtubechannel-lightbox").addClass("showing");
          $("#youtubechannel-list").addClass("lightbox-showing");
          $("#youtubechannel-player iframe")[0].src =
            $("#youtubechannel-player iframe")[0].src +
            "?enablejsapi=1&autoplay=1";
        });

        $(document).on("click", function (e) {
          if (
            e.target.id != "youtubechannel-lightbox" &&
            e.target.id != "youtubechannel-frame" &&
            $("#youtubechannel-player iframe").length &&
            $("#youtubechannel-lightbox").hasClass("showing")
          ) {
            $("#youtubechannel-lightbox").removeClass("showing");
            $("body").removeClass("lightbox-showing");
            $("#youtubechannel-list").removeClass("lightbox-showing");
            $("#youtubechannel-player iframe")[0].src = "";
          }
        });

        if (window.innerWidth > 786) {
          setupMagnificPopup(
            ".paragraph--type--image-and-caption a.image-link"
          );
        }

        if ($(".blog .paragraph--type--single-feature-container").length) {
          $(
            ".blog .paragraph--type--single-feature-container .article-container"
          ).prepend(
            $('<p class="pre_title_flag">Tickets Mentioned in this Article</p>')
          );
        }

        if ($(".blog .paragraph--type--three-featured-tickets").length) {
          $(
            ".blog .paragraph--type--three-featured-tickets .content-top"
          ).prepend(
            $('<p class="pre_title_flag">Tickets Mentioned in this Article</p>')
          );
          $(".blog .paragraph--type--three-featured-tickets").each(function (
            i,
            el
          ) {
            if ($(el).find(">div").length == 2) {
              $(el).addClass("two-col");
            }
          });

          if (window.innerWidth > 786) {
            $(".blog .paragraph--type--three-featured-tickets > div").each(
              function (i, el) {
                $(el)
                  .find(".field_prices")
                  .appendTo($(el).find(".field_price_info"));
              }
            );
          } else {
            $(
              ".blog .paragraph--type--three-featured-tickets  div.owl-item"
            ).each(function (i, el) {
              $(el)
                .find(".field_prices")
                .appendTo($(el).find(".field_price_info"));
            });
          }
        }

        const blogGridItems = document.querySelectorAll(
          "#block-esb-theme-views-block-blog-block-1 .views-row .single-blog-wrapper"
        );
        blogGridItems.forEach((item, a) => {
          item.addEventListener("mouseover", function (e) {
            const desc = item.querySelector("p.description");
            desc.style.height = desc.scrollHeight + "px";
          });
          item.addEventListener("mouseout", function (e) {
            const desc = item.querySelector("p.description");
            desc.style.height = "0px";
          });
        });

        if (
          $(".field_related_articles .view-content > .views-row").length > 3
        ) {
          $(".field_related_articles .view-content").addClass("three-rows");
        }

        if (window.innerWidth < 1080) {
          $(".exposed-filter-values").insertAfter(
            $("#views-exposed-form-blog-block-1 .fieldgroup")
          );
        }

        // instagram feed
        var feed_position = 65;

        // next instagram picture
        $(".nav_next").click(function () {
          var width = $(".instagram_feed").width();
          var stage_width = $(".instagram_feed .cr_wrapper").width();
          var stop_at = width - stage_width;

          if (feed_position > stop_at) {
            var $stage = $(".cr_wrapper");
            var $assets = $(".cr__asset");
            var $prev = $assets.filter(".active");

            if (!$prev.length) {
              $prev = $assets.first();
              $prev.addClass("active");
            }

            var $next = $prev.next();

            if ($next.length) {
              feed_position -= $prev.outerWidth();
              $prev.removeClass("active");
              $next.addClass("active");
              $stage.css("transform", "translateX(" + feed_position + "px)");
            }
          }
        });

        // prev instagram picture
        $(".nav_prev").click(function () {
          var $stage = $(".cr_wrapper");
          var $assets = $(".cr__asset");
          var $prev = $assets.filter(".active");

          if (!$prev.length) {
            $prev = $assets.first();
            $prev.addClass("active");
          }

          var $next = $prev.prev();

          if ($next.length) {
            feed_position += $next.outerWidth();
            $prev.removeClass("active");
            $next.addClass("active");
            $stage.css("transform", "translateX(" + feed_position + "px)");
          }
        });

        const bgVideo = document.querySelector("video.video-background");
        const fallbackImg = document.querySelector(".video-fallback-img");
        if (bgVideo) {
          let startPlayPromise = bgVideo.play();
          if (startPlayPromise !== undefined) {
            startPlayPromise
              .then((res) => {
                console.log(res);
              })
              .catch((error) => {
                bgVideo.style.opacity = "0";
                fallbackImg.style.opacity = "1";
              });
          }
        }

        // END: onLoad()
        return true;
      } // END: onLoad()

      // register event listener
      $(window).on("load", onLoad);

      //////////////////////////////////////////////////////////////
      // helper functions
      //////////////////////////////////////////////////////////////

      // featured slider url query

      function sliderUrlQuery() {
        if (
          window.location.hash == "#feature-slider" &&
          window.location.search.includes("?slide")
        ) {
          var slide = window.location.search.split("=")[1];

          $("#feature-slider .field_single_feature_slider.owl-loaded").trigger(
            "stop.owl.autoplay"
          );
          $("#feature-slider .field_single_feature_slider.owl-loaded").trigger(
            "to.owl.carousel",
            slide - 1
          );

          document.querySelector("#feature-slider").scrollIntoView();
        }
      }

      // load more masonry reslts function

      function loadMore(toShow) {
        $grid.find(".hidden").removeClass("hidden");

        var hiddenElems = iso.filteredItems
          .slice(toShow, iso.filteredItems.length)
          .map(function (item) {
            return item.element;
          });

        $(hiddenElems).addClass("hidden");
        $grid.isotope("layout");

        // when no more to load, hide show more button
        if (hiddenElems.length == 0) {
          $("#showMore").hide();
        } else {
          $("#showMore").show();
        }
      }

      // debounce so filtering doesn't happen every millisecond

      function debounce(fn, threshold) {
        var timeout;

        threshold = threshold || 100;

        return function debounced() {
          clearTimeout(timeout);

          var args = arguments;
          var _this = this;

          function delayed() {
            fn.apply(_this, args);
          }

          timeout = setTimeout(delayed, threshold);
        };
      }

      // logic for setting cookies

      function setCookie(name, value, days) {
        var date = new Date();
        date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
        var expires = date.toUTCString();

        document.cookie =
          name + "=" + value + "; expires=" + expires + "; path=/";
      }

      // logic for getting cookies

      function getCookie(name) {
        var name_str = name + "=";
        var cookies = document.cookie.split(";");

        for (var i = 0; i < cookies.length; i++) {
          var c = cookies[i];

          while (c.charAt(0) == " ") {
            c = c.substring(1);
          }

          if (c.indexOf(name_str) == 0) {
            return c.substring(name_str.length, c.length);
          }
        }

        return "";
      }

      // center slides

      function centerSlides(event) {
        var element = $(event.target);

        if (!winsize.desktop) {
          element.trigger("to.owl.carousel", 1);
        }
      }

      // parse chapters

      function parseChapters(chapters, ux) {
        $(".field_chapter").each(function (i, el) {
          var $el = $(el);

          chapters.push({
            li: $("<li/>").append($("<span/>").html($el.html())).appendTo(ux),
            y: $el.offset().top,
          });
        });
      }

      // start timeline

      function startTimeline(chapters, ux, curr_chapter) {
        var curr = 0;
        var winheight = $(window).height();
        var midPt = parseInt(window.scrollY + winheight / 2);

        for (var i = 0; i < chapters.length; i++) {
          if (chapters[i].y < midPt) {
            curr = i;
          }
        }

        if (curr_chapter !== curr) {
          ux.find(".active").removeClass("active");
          chapters[curr].li.addClass("active");
          curr_chapter = curr;
        }

        var container = $(".node--type-timeline");
        var menu = $(".chapter-ux");
        var offset = $(".chapter-ux").offset();
        var menuB = parseInt(offset.top + menu.height());
        var lastBlock = container.find(".field_sections > .paragraph").last();
        var lastH = lastBlock.height();
        var lastO = lastBlock.offset().top;
        var lastB = parseInt(lastO + lastH);

        if (menuB > lastB) {
          menu.addClass("is-hidden");
        } else {
          menu.removeClass("is-hidden");
        }
      }

      // survey cta

      function surveyCTA() {
        var $block = $(".home_page_ .survey-cta");

        $block.on("click", ".teaser", function () {
          $block.addClass("active");
        });

        $block.on("click", ".close", function () {
          $block.removeClass("active");
        });

        // show on scroll
        window.addEventListener(
          "scroll",
          function () {
            // if on tablet or mobile, skip
            if ($(window).width() <= 1024) {
              return;
            }

            // if opacity is already 1, skip
            var opacity = $(document).scrollTop() / 1000;
            if (opacity > 1) {
              return;
            }

            // opacity is proportional to the scroll for the kikz
            $block.css("opacity", opacity);
          },
          { passive: true }
        );
      }

      // lightboxes

      function setupMagnificPopup(selectors) {
        var isVideoSliderPara = $(selectors).parents(
          ".paragraph--type--video-slider"
        ).length;

        $(selectors).magnificPopup({
          type: "image",
          closeOnContentClick: false,
          closeBtnInside: true,
          mainClass: `mfp-with-zoom mfp-img-mobile ${
            selectors == ".paragraph--type--image-and-caption a.image-link"
              ? "image-carousel-paragraph"
              : ""
          } ${isVideoSliderPara ? "video-slider-paragraph" : ""}`,
          fixedContentPos: true,
          image: {
            verticalFit: false,
            titleSrc: function (item) {
              var parent = $(item.el).closest("article");

              if (parent.find(".field_description").length) {
                return parent.find(".field_description").html();
              } else {
                return "";
              }
            },
          },
          iframe: {
            markup:
              '<div class="mfp-iframe-scaler">' +
              '<div class="mfp-close"></div>' +
              '<iframe class="mfp-iframe" frameborder="0" allowfullscreen></iframe>' +
              '<div class="mfp-bottom-bar"><div class="mfp-title"></div></div>' +
              "</div>",
          },
          gallery: {
            enabled: true,
            arrowMarkup:
              '<button title="%title%" type="button" class="mfp-arrow mfp-arrow-%dir%"></button>',
            tCounter: "",
          },
          zoom: {
            enabled: true,
            duration: 300, // don't foget to change the duration also in CSS
            opener: function (element) {
              return element.find("img");
            },
          },
          callbacks: {
            elementParse: function (item) {
              if (item.el.get(0).className == "video-link") {
                item.type = "iframe";
              } else {
                item.type = "image";
              }
            },
            markupParse: function (template, values, item) {
              var blockItem = item.el.get(0);
              var parent = $(blockItem).closest("article").get(0);
              if ($(blockItem).hasClass("image-link")) {
                values.title = $(blockItem).find(".field_description").html();
              } else if ($(parent).find(".field_description").length > 0) {
                values.title = $(parent).find(".field_description").html();
              } else if ($(blockItem).find("img").length > 0) {
                values.title = $(blockItem).find("img").attr("alt");
              } else {
                values.title = "";
              }
            },
          },
        });
      }

      // celebrity page

      function openCelebPic() {
        if (
          window.location.pathname == "/about/celebrities-at-esb" &&
          window.location.search.includes("?")
        ) {
          var celeb_id = window.location.search.split("?celeb=")[1];

          if (celeb_id) {
            $("#" + celeb_id + " .field_media_image > a").click();
          }
        }
      }

      // window size helper

      function getWinSize() {
        return {
          desktop: jQuery(window).width() >= 1025,
          tablet:
            jQuery(window).width() >= 768 && jQuery(window).width() < 1025,
          mobile: jQuery(window).width() <= 767,
        };
      }

      // runs during everytime Drupal.behaviors.esb_theme.attach() is called

      function applyChangesDuringEveryAttach(context, settings) {
        if (
          context.querySelectorAll("select:not(.select2-hidden-accessible)")
            .length > 0
        ) {
          $("select:not(.select2-hidden-accessible)", context).each(
            function () {
              applySelect2(this, settings);
            }
          );
        }
      }

      // apply select2 field

      function applySelect2(jQueryThis, settings) {
        var me = $(jQueryThis);

        if (me.hasClass("lang-dropdown-select-element")) {
          var wrapper = me.parents(".langdropdown-wrapper");
          var opts = wrapper.find("> .options-wrapper");
          var info = settings.lang_info.langs;

          $("<span/>")
            .addClass("lang-code")
            .html(
              '<img src="' +
                info[settings.lang_info.curr].flag +
                '"/>' +
                info[settings.lang_info.curr].code
            )
            .insertAfter(me);

          me.select2({
            minimumResultsForSearch: Infinity,
            width: 70,
            dropdownParent: opts,
            templateResult: function (item) {
              var base = $('<span class="img-flag"/>');
              if (info[item.id]) {
                base.append($("<img/>").attr("src", info[item.id].flag));
              }
              return base.append(item.text);
            },
          });

          me.on("select2:open", function (e) {
            $(opts).addClass("opened");
          });

          me.on("select2:close", function (e) {
            $(opts).removeClass("opened");
          });

          me.on("select2:select", function (e) {
            var form = me.closest("form");
            form.submit();
          });

          winsize = getWinSize();

          $(wrapper).hover(
            function (e) {
              if (winsize.desktop) {
                e.preventDefault();
                me.select2("open");
              }
            },
            function () {
              if (winsize.desktop) {
                me.select2("close");
                $(".select2-selection__rendered").blur();
              }
            }
          );
        } else {
          me.select2({
            minimumResultsForSearch: Infinity,
          });
        }
      }

      // send localStorage refersion variables to ticketing subdomain

      function refersionTrackingCrossDomain() {
        const rfsn = {
          id: localStorage.getItem("rfsn_v4_id"),
          aid: localStorage.getItem("rfsn_v4_aid"),
          cs: localStorage.getItem("rfsn_v4_cs"),
        };
        const rfsnExists = Object.values(rfsn).every(
          (v) => v !== "" && v !== null
        );
        if (rfsnExists) {
          const ticketingATags = document.querySelectorAll(
            'a[href^="https://ticketing."]'
          );
          ticketingATags.forEach((aTag) => {
            const url = new URL(aTag.href);
            // need to check for searchParams already added
            if (url.searchParams.size > 0) {
              aTag.href =
                aTag.href +
                `&rfsn_v4_id=${rfsn.id}&rfsn_v4_aid=${rfsn.aid}&rfsn_v4_cs=${rfsn.cs}`;
            } else {
              aTag.href =
                aTag.href +
                `?rfsn_v4_id=${rfsn.id}&rfsn_v4_aid=${rfsn.aid}&rfsn_v4_cs=${rfsn.cs}`;
            }
          });
        }
      }

      // toggle image visibility based on screen width within single feature container
      function toggleImageVisibility() {
        const imageContainers = document.querySelectorAll(
          ".article-img-container"
        );
        imageContainers.forEach((container) => {
          const desktopImage = container.querySelector(".field_media_sf");
          const mobileImage = container.querySelector(".field_media_mobile");
          if (desktopImage && mobileImage) {
            if (window.innerWidth <= 1024) {
              desktopImage.style.display = "none";
              mobileImage.style.display = "block";
            } else {
              desktopImage.style.display = "block";
              mobileImage.style.display = "none";
            }
          } else if (desktopImage) {
            // always show desktop image if no mobile image is available
            desktopImage.style.display = "block";
          }
        });
      }
      toggleImageVisibility();
      window.addEventListener("resize", toggleImageVisibility);

      // lazy-loading of 3rd party scripts and heavy assets
      if ("IntersectionObserver" in window) {
        window.addEventListener("DOMContentLoaded", function () {
          mainPageConfig.forEach((config) => {
            const elements = [...document.querySelectorAll(config.class)];
            if (elements.length > 0) {
              [...elements].map((el) => observer.observe(el));
            }
          });
        });
      } else {
        mainPageConfig.forEach((config) => {
          const elements = [...document.querySelectorAll(config.class)];
          if (elements.length > 0) {
            elements.map(loadImmediately);
          }
        });
      }

      window.addEventListener("DOMContentLoaded", function () {
        window._loadedVentrataScript = false;
        // accessibility
        fixAccessibilityIssuesWithInterval(fixAccessibilityIssues);

        const ventrataLink = $("body a[ventrata-checkout]");
        if (ventrataLink.length > 0) {
          if ($("body").hasClass("home_page_")) {
            ventrataLink.on("click", (e) => {
              loadVentrataScript(e, true);
            });
          } else {
            loadVentrataScript(undefined, false);
          }
        }

        // refersion tracking
        loadRefersionTracking(
          window,
          document,
          "script",
          "https://cdn.refersion.com/refersion.js",
          "r",
          function () {
            // Configure SDK
            r.pubKey = "pub_0724f9577a3fbce5191d";
            // Initialize
            r.initializeXDLS().then(() => {
              r.launchDefault();
            });
          }
        );
      });

      //////////////////////////////////////////////////////////////
      // end of file
      //////////////////////////////////////////////////////////////
    },
  };
})(window.jQuery, window.Drupal, window);
