export const lazyLoadBgImage = (config, targetElement) => {
  if (targetElement.dataset.bgimage) {
    targetElement.style.backgroundImage =
      "url('" + targetElement.dataset.bgimage + "')";
  }

  targetElement.classList.add(config.classToAdd);

  return true;
};
