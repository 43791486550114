export const loadVentrataScript = (event, shouldClick) => {
  const el = $("<script />", {
    async: true,
    src: "https://cdn.checkout.ventrata.com/v3/production/ventrata-checkout.min.js",
    type: "module",
    onload: () => {
      if (!shouldClick || window._loadedVentrataScript) {
        return;
      }

      let i = 0;
      const intervalId = setInterval(function () {
        if (window._loadedVentrataScript || i > 10) {
          clearInterval(intervalId);
          return;
        }

        if ($("ventrata-checkout-element").length) {
          window._loadedVentrataScript = true;
          clearInterval(intervalId);
          return;
        } else if (event) {
          event.target.click();
        }

        i++;
      }, 1500);
    },
    "data-config":
      '{"apiKey":"b654851d-20e1-423b-bbe7-6ace1f676f42", "env": "live", "lang": "en", "defaultTheme": "dark", "features": { "promoAndGiftCardInput": false, "timePickerType": "select", "availability": false }, "theme": { "dark": { "primary": "#E2CD83", "gradient-A": "#b49e4f", "gradient-B": "#b49e4f"} } }',
  });

  const parent = $("body");
  parent.append(el);
};
