export const load3rdPartyScript = (config) => {
  const script = document.createElement("script");
  script.src = config.src;
  script.async = true;
  script.defer = true;
  script.onload = () => {
    config.onload();
  };

  Object.entries(config.attributes).forEach(([key, value]) => {
    if (key !== "src" && key !== "onload") {
      script.setAttribute(key, value);
    }
  });

  document.querySelector(config.parentClass).appendChild(script);

  return true;
};
