import { load3rdPartyScript } from "./load3rdPartyScript";
import { lazyLoadBgImage } from "./lazyLoadBgImage";

export const loadImmediately = (element) => {
  if (element.data("lazy_loaded") !== "true") {
    if (config.action === "load3rdPartyScript" && load3rdPartyScript(config)) {
      $(element).data("lazy_loaded", "true"); // Mark as handled
      return;
    }

    if (
      config.action === "lazyLoadBgImage" &&
      lazyLoadBgImage(config, element)
    ) {
      $(element).data("lazy_loaded", "true"); // Mark as handled
      return;
    }
  }
};
