// accessibility fixes for the main page
function fixImageAlts() {
  // search for all img elements
  $("img").each(function () {
    if (!$(this).attr("alt")) {
      $(this).attr("alt", "");
    }
  });
}

function fixFormLabels() {
  // search for all input and textarea elements
  $(".grecaptcha-badge input, .grecaptcha-badge textarea").each(function () {
    if (!$(this).attr("id")) {
      const randomId = "id-" + Math.random().toString(36).substring(2, 11);
      $(this).attr("id", randomId);
    }

    const label = $('label[for="' + $(this).attr("id") + '"]');
    if (label.length === 0) {
      var newLabel = $("<label></label>")
        .attr("for", $(this).attr("id"))
        .text("Label for " + $(this).prop("tagName").toLowerCase())
        .hide();
      $(this).before(newLabel);
    }
  });
}

function removeGtranslateTitles() {
  // search for all gtranslate elements
  $(".gt_switcher .gt_option a").each(function () {
    $(this).removeAttr("title");
  });
}

export function shortenInstaImgAlts() {
  // search for all body img elements
  $(".instagram_feed .cr__asset__wrapper img").each(function () {
    console.log("" + new Date() + ": fixing alt for insta img");
    const alt = $(this).attr("alt");
    if (!alt) {
      return;
    }

    const cutString1 = alt
      .replace(/#[\p{L}_0-9]{1,}/gu, "")
      .replace(/[\r\n]{1,}/gu, " ")
      .replace(/\s{2,}/gu, " ");
    let result =
      cutString1.length > 100 ? cutString1.substring(0, 101) : cutString1;

    if (
      result[result.length - 2] !== " " &&
      result[result.length - 1] !== " "
    ) {
      result = result.split(" ").slice(0, -1).join(" ");
    } else if (result[result.length - 2] === " ") {
      result = result.substring(0, result.length - 2);
    } else {
      result = result.substring(0, result.length - 1);
    }
    $(this).attr("alt", result);
  });
}

export function fixAccessibilityIssues(fixed) {
  fixImageAlts();
  fixFormLabels();
  if (fixed === 0) {
    removeGtranslateTitles();
  }
  fixed += 1;
}

export const fixAccessibilityIssuesWithInterval = (callback) => {
  if (!$("body").hasClass("home_page_")) {
    return;
  }

  let fixed = 0;
  const intervalTime = 1000;
  const timeout = 4000;

  callback();

  const intervalId = setInterval(() => {
    callback(fixed);
  }, intervalTime);

  setTimeout(() => {
    clearInterval(intervalId);
  }, timeout);
};
