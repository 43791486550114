import { mainPageConfig } from "./mainPageConfig";
import { load3rdPartyScript } from "./load3rdPartyScript";
import { lazyLoadBgImage } from "./lazyLoadBgImage";

export const observer = new IntersectionObserver(
  function (entries, observer) {
    entries.forEach((entry) => {
      let targetElement = entry.target;
      let config = mainPageConfig.find((e) =>
        targetElement.classList.contains(e.class.replace(".", ""))
      );

      if (entry.isIntersecting || config.loadImmediately) {
        if (config && !targetElement.dataset.observed) {
          if (config.action === "load3rdPartyScript") {
            targetElement.classList.add("loading");
            load3rdPartyScript(config);
            observer.unobserve(targetElement);
            targetElement.dataset.observed = "true"; // Mark as handled
            return;
          }

          if (
            config.action === "lazyLoadBgImage" &&
            lazyLoadBgImage(config, targetElement)
          ) {
            observer.unobserve(targetElement);
            targetElement.dataset.observed = "true"; // Mark as handled
            return;
          }
        }
      }
    });
  },
  { rootMargin: "200px" }
);
